@media (max-width: 1199px) {
  .price-page-wrapper {
    padding: 0 3rem; } }

.price-column {
  border: 1px solid #eee;
  border-radius: 0.25rem;
  margin-bottom: 72px; }
  @media (max-width: 991px) {
    .price-column {
      max-width: 480px;
      margin-right: auto;
      margin-left: auto; } }

.pc__footer .btn {
  width: 100%;
  padding: .75rem 1rem;
  font-size: .875rem; }

.star-rating {
  color: gold; }
  .star-rating i {
    font-size: 1.25rem;
    margin-right: 4px; }

.price-plan-name {
  width: 80%;
  max-width: 180px;
  height: 42px;
  line-height: 42px;
  margin: 0 auto -20px;
  border-radius: 1000px; }
